// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/SF-Pro-Rounded-Black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/SF-Pro-Rounded-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/SF-Pro-Rounded-Black.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/SF-Pro-Rounded-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/public/fonts/SF-Pro-Rounded-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/public/fonts/SF-Pro-Rounded-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/public/fonts/SF-Pro-Display-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/public/fonts/SF-Pro-Display-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("/public/fonts/SF-Pro-Display-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-style: auto;
  /* font-weight: 700; */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})  format('opentype'),
  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2'),
  url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff')
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-style: auto;
  /* font-weight: 600; */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___})  format('opentype'),
  url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
  url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff')
}


@font-face {
  font-family: 'SF Pro Display';
  font-style: auto;
  /* font-weight: 500; */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___})  format('opentype'),
  url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff2'),
  url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff')
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,sBAAsB;EACtB;;;AAGF;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,sBAAsB;EACtB;;;AAGF;;;AAGA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,sBAAsB;EACtB;;;AAGF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: 'SF Pro Rounded';\n  font-style: auto;\n  /* font-weight: 700; */\n  src: url('~/public/fonts/SF-Pro-Rounded-Black.otf')  format('opentype'),\n  url('~/public/fonts/SF-Pro-Rounded-Black.woff2') format('woff2'),\n  url('~/public/fonts/SF-Pro-Rounded-Black.woff') format('woff')\n}\n\n@font-face {\n  font-family: 'SF Pro Rounded';\n  font-style: auto;\n  /* font-weight: 600; */\n  src: url('~/public/fonts/SF-Pro-Rounded-Bold.otf')  format('opentype'),\n  url('~/public/fonts/SF-Pro-Rounded-Bold.woff2') format('woff2'),\n  url('~/public/fonts/SF-Pro-Rounded-Bold.woff') format('woff')\n}\n\n\n@font-face {\n  font-family: 'SF Pro Display';\n  font-style: auto;\n  /* font-weight: 500; */\n  src: url('~/public/fonts/SF-Pro-Display-Medium.otf')  format('opentype'),\n  url('~/public/fonts/SF-Pro-Display-Medium.woff2') format('woff2'),\n  url('~/public/fonts/SF-Pro-Display-Medium.woff') format('woff')\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
